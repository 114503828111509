import api from "../utils/api"

export const security_api_fetchClientData = async () => {
  var ret = {}

  await api().get("/api/security/fetchClientData")
    .then((response) => {
      ret = { status: "OK", data: response.data, code: "", message: "Successfully submitted." }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const security_api_getInfo = async () => {
  var ret = {}
  await api().get("/api/security/getInfo")
    .then((response) => {
      ret = { status: "OK", data: response.data, code: "", message: "" }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", message: "Network Error", code: "" }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}
