import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../utils/AuthContext";
import GlobalVarContext from "../utils/GlobalVarContext";

const NavbarPioneer = () => {
  let { navigator } = useContext(GlobalVarContext)

  const [navbarMarkup, setNavbarMarkup] = useState()
  const location = useLocation();
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  useEffect(() => {
    if (navigator) {
      let _navbar_content = navigator.filter(item => {
        if (['account'].includes(item.name)) {
          return false
        }
        if (item.hasOwnProperty('items')) {
          if (item.items.length === 0) {
            return false
          }
        }
        return true
      })
      if (Array.isArray(_navbar_content) && _navbar_content.length > 0) {
        setNavbarMarkup(
          _navbar_content.map((item, index) => {
            return (
              <Link key={index} to={item.path} className="block sm:inline-block w-full sm:w-auto" >
                <button
                  style={{ fontSize: 12 }}
                  className={
                    "cursor-pointer font-montserrat font-semibold uppercase py-3 w-full sm:w-40 rounded-md " + ((index === (_navbar_content.length - 1)) ? "ml-0 sm:ml-2" : "mx-0 sm:mx-2") +
                    (
                      (item.path.split("/")[1] === location.pathname.split("/")[1])
                        ? " bg-blue-400 text-white "
                        : " bg-white hover:bg-ss-200 text-gray-900 hover:text-gray-800 "
                    )
                  }
                >
                  {`${item.text}`}
                </button>
              </Link>
            )
          }
          )
        )
      }

    }
  }, [navigator, location.pathname])

  return (
    <>
      <div className="mobile-menu-wrp flex justify-end sm:hidden absolute top-7 right-2">
        <div className="mobile-menu-toggle" onClick={handleToggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`menu-item-wrp sm:flex flex-col space-y-2 sm:space-y-0 sm:flex-row justify-start sm:justify-end items-center sm:items-end ${navbarOpen ? "" : "hidden"}`}>
        {
          navbarMarkup
        }
      </div>
    </>
  )
}

export default NavbarPioneer