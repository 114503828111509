const routes = [
  {
    path: '/',
    page: 'Home',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'auth.home'
  },
  {
    path: '/projects/all',
    page: 'AllProjectsHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'project.view.all'
  },
  {
    path: '/projects/completed',
    page: 'CompletedProjectsHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'project.view.completed'
  },
  {
    path: '/projects/current',
    page: 'CurrentProjectsHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'project.view.current'
  },
  {
    path: '/projects/create',
    page: 'CreateProjectHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'project.create'
  },
  {
    path: '/search/projects',
    page: 'SearchProjectsHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'project.view.all'
  },
  {
    path: '/projects/:id',
    page: 'EditProjectHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'project.create'
  },
  {
    path: '/users/all',
    page: 'AllUsersHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'user.view.all'
  },
  {
    path: '/users/create',
    page: 'CreateUserHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'user.create'
  },
  {
    path: '/users/:id',
    page: 'EditUserHandler',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'user.create'
  },
  {
    path: '/test',
    page: 'TestPage',
    folder: 'app/pages',
    isPrivate: true,
    exact: true,
    grant: 'user.create'
  },
];


export default routes;