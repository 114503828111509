import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { auth_api_is_alive, auth_api_logout, auth_api_set_csrf_cookie } from '../api/auth_api';
import { security_api_getInfo } from '../api/security_api';
import history from "./history"
import { user_login_history_api_iAmActive, user_login_history_api_iAmInActive } from '../api/user_login_history_api';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState()
  const [user, setUser] = useState()
  const [isCsrfOk, setIsCsrfOk] = useState(false)
  const [authErr, setAuthErr] = useState()
  const [permissions, setPermissions] = useState()
  const location = useLocation()

  var hidden, visibilityChange;

  if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }

  const handleTabFocusChange = (e) => {
    if (document[hidden]) {
      user_login_history_api_iAmInActive()
    } else {
      user_login_history_api_iAmActive()
    }
  }

  useEffect(() => {
    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
      console.error("Activity logging is not enabled");
    } else {
      document.addEventListener(visibilityChange, handleTabFocusChange);
    }
    return (() => {
      document.removeEventListener(visibilityChange, handleTabFocusChange);
    })
  }, [])

  useEffect(() => {
    auth_api_set_csrf_cookie()
      .then(csrf => {
        if (csrf.status === "OK") {
          setIsCsrfOk(true)
          auth_api_is_alive()
            .then(alive => {
              if (alive.status === "OK") {
                security_api_getInfo()
                  .then(info => {
                    if (info.status === "OK") {
                      setUser(info.data.user)
                      setPermissions(info.data.permissions)
                      setIsAuthed(true)
                    } else {
                      setUser()
                      setPermissions()
                    }
                  })
              } else {
                setAuthErr(alive.message)
                setIsAuthed(false)
                setUser()
                setPermissions()
                const loc = {
                  pathname: '/login',
                  state: { from: location }
                }
                history.push(loc)
              }
            })
        } else {
          setAuthErr(csrf.message)
          setIsCsrfOk(false)
          setIsAuthed(false)
          setUser()
          setPermissions()
        }
      })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        permissions: permissions,
        setPermissions: (val) => setPermissions(val),
        isAuthed: isAuthed,
        setIsAuthed: (val) => setIsAuthed(val),
        isCsrfOk: isCsrfOk,
        authErr: authErr,
        user: user,
        setUser: (val) => setUser(val),
        logout: (redirect) => {
          auth_api_logout()
            .then(res => {
              setIsAuthed(false)
              setUser()
              const loc = {
                pathname: '/login',
                state: { from: location }
              }
              if (redirect !== "") {
                history.push(redirect)
              } else {
                history.push(loc)
              }
            })
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}