
import React, { Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from "react-router-dom";
import history from "./_core/utils/history"
import './App.css';
import { AuthProvider } from './_core/utils/AuthContext';
import { GlobalVarProvider } from './_core/utils/GlobalVarContext';
import RouteStore from './_core/components/RouteStore';
import EventBus from './_core/utils/EventBus';

function AppPioneer() {
  //#region Outside click handler
  const handleClickOutside = (e) => {
    EventBus.dispatch("outsideClicked", "")
  }

  const handleWindowBlur = (e) => {
    // user_login_history_api_iAmInActive()
  }

  const handleWindowFocus = (e) => {
    // user_login_history_api_iAmActive()
  }
  //#endregion

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);

    return (() => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
    })
  }, [])

  return (
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <GlobalVarProvider>
          <HelmetProvider>
            <Suspense fallback={<div></div>}>
              <RouteStore />
            </Suspense>
          </HelmetProvider>
        </GlobalVarProvider>
      </AuthProvider>
    </Router >
  )
}

export default AppPioneer;
