import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../utils/AuthContext"
import GlobalVarContext from "../utils/GlobalVarContext"
import { Link, useLocation } from "react-router-dom"

const SubNavbar = () => {

  let { navigator } = useContext(GlobalVarContext)
  const auth = useContext(AuthContext)

  const [sidebarMarkup, setSidebarMarkup] = useState()
  const location = useLocation();

  const setupSidebar = () => {
    if (location.pathname !== "/") {
      if (navigator) {
        let _sidebar_content = navigator.filter(item => {
          if (['account'].includes(item.name)) {
            return false
          }
          if (item.hasOwnProperty('items')) {
            if (item.items.length === 0) {
              return false
            }
          }
          return true
        })
        let _sidebar_links = []
        if (Array.isArray(_sidebar_content) && _sidebar_content.length > 0) {
          _sidebar_links = _sidebar_content.filter(item => {
            let rootloc = location.pathname.split("/")[1]
            return (item.name === rootloc)
          })
          if (_sidebar_links.length > 0) {
            if (_sidebar_links[0].hasOwnProperty("items")) {
              _sidebar_links = _sidebar_links[0].items
              setSidebarMarkup(
                _sidebar_links.map((item, index) =>
                  <Link key={index} to={item.path} className="block sm:inline-block w-full sm:w-auto" >
                    
                      <button
                        className={
                          "w-full cursor-pointer font-montserrat font-semibold uppercase px-4 py-3 text-black" +
                          (
                            item.path === location.pathname
                              ? " bg-white border-b-4 border-blue-400 "
                              : " hover:border-gray-200 border-b-4 border-transparent "
                          )
                        }
                        style={{ fontSize: 12 }}
                      >
                        {item.text}
                      </button>
                  </Link>
                )
              )
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    setupSidebar()
  }, [navigator, location.pathname])

  return (
    <>
      {
        (auth.isAuthed) && (location.pathname !== "/") && sidebarMarkup && sidebarMarkup
      }
      {
        !sidebarMarkup && <></>
      }
    </>
  )
}

export default SubNavbar;