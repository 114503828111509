import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "./AuthContext"
import { security_api_fetchClientData } from '../api/security_api';
import _ from "lodash"

export const GlobalVarContext = React.createContext();

export const GlobalVarProvider = ({ children }) => {
  const [navigator, setNavigator] = useState()
  const [sidebarOpen, setSidebarOpen] = useState((localStorage.getItem('isSidebarOpen') ?? false) === "true")

  const toggleSidebar = () => {
    localStorage.setItem('isSidebarOpen', !sidebarOpen)
    setSidebarOpen(prev => !prev)
  }

  const auth = useContext(AuthContext)

  useEffect(() => {
    if (auth.isAuthed) {

      security_api_fetchClientData()
        .then(res => {
          if (res.status === "OK") {
            if (!_.isEmpty(res.data)) {
              setNavigator(res.data.navs.items)
            }
          }
        })
    }
  }, [auth.isAuthed])

  return (
    <GlobalVarContext.Provider
      value={{
        navigator: navigator,
        sidebarOpen: sidebarOpen,
        toggleSidebar: toggleSidebar
      }}
    >
      {children}
    </GlobalVarContext.Provider>
  );
}

export default GlobalVarContext;