import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faBorderAll, faChevronRight, faEdit, faFile, faHome } from '@fortawesome/free-solid-svg-icons'
import GlobalVarContext from "../utils/GlobalVarContext"
import { AuthContext } from "../utils/AuthContext"
import { Link, useLocation } from "react-router-dom"

const SidebarPioneer = () => {
  let { navigator } = useContext(GlobalVarContext)
  const auth = useContext(AuthContext)

  const [sidebarMarkup, setSidebarMarkup] = useState()
  const location = useLocation();

  const iconMap = {
    faHome: faHome,
    faBorderAll: faBorderAll,
    faEdit: faEdit,
    faFile: faFile
  }

  const setupSidebar = () => {
    if (location.pathname !== "/") {
      if (navigator) {
        let _sidebar_content = navigator.filter(item => {
          if (['account'].includes(item.name)) {
            return false
          }
          if (item.hasOwnProperty('items')) {
            if (item.items.length === 0) {
              return false
            }
          }
          return true
        })
        let _sidebar_links = []
        if (Array.isArray(_sidebar_content) && _sidebar_content.length > 0) {
          _sidebar_links = _sidebar_content.filter(item => {
            let rootloc = location.pathname.split("/")[1]
            return (item.name === rootloc)
          })
          if (_sidebar_links.length > 0) {
            if (_sidebar_links[0].hasOwnProperty("items")) {
              _sidebar_links = _sidebar_links[0].items
              setSidebarMarkup(
                _sidebar_links.map((item, index) =>
                  <Link key={index} to={item.path} className="" >
                    <li >
                      <button
                        className={
                          "font-montserrat font-semibold pl-6 py-3 w-full text-left" +
                          (
                            item.path === location.pathname
                              ? " bg-white border-r-4 border-blue-400 "
                              : " hover:bg-ss-300 text-gray-800 border-r-4 border-transparent "
                          )
                        }
                        style={{ fontSize: 14 }}
                      >
                        {item.text}
                      </button>
                    </li>
                  </Link>
                )
              )
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    setupSidebar()
  }, [navigator, location.pathname])

  return (
    <>
      {
        (auth.isAuthed) && (location.pathname !== "/") && sidebarMarkup &&
        <ul>
          {
            sidebarMarkup
          }
        </ul >
      }
      {
        !sidebarMarkup &&
        <></>
      }
    </>
  )
}

export default SidebarPioneer